<template>
    <div>
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item >基础设置</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/slideShow' }">播图设置</el-breadcrumb-item>
                <el-breadcrumb-item>新增</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="detail-info">
            <el-form ref="form" :model="form" label-width="120px" size="mini" >

                <el-form-item label="图片：" label-position="left" >
                    <select-images @onSelect="onSelect" @onDelete="onDelete" :image="image"></select-images>
                </el-form-item>

                <el-form-item label="跳转类型：" label-position="left" class="input" >
                    <el-select style="width: 200px" v-model="form.type" placeholder="请选择" @change="selectType">
                        <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="页面路径" label-position="left" class="input" v-if="form.type !== 0">
                    <el-input style="width: 200px" placeholder="/pages/index/index?id=1" clearable  v-model="form.resource"></el-input>
                </el-form-item>

                <el-form-item label="状态：" label-position="left" class="input" >
                    <el-switch v-model="form.status" @change="setStatus">
                    </el-switch>
                    <span style="margin-left: 5px;font-size: 12px">{{statusTxt}}</span>
                </el-form-item>


                <el-form-item>
                    <el-button size="small"  type="primary" :loading="loading" @click="onSubmit">保存</el-button>
                    <el-button style="margin-left: 10px" size="small" @click="$router.go(-1)">取消</el-button>
                </el-form-item>
            </el-form>

        </div>
    </div>
</template>

<script>
    import {mapActions} from "vuex";
    import SelectImages from "../../../components/selectImages/index";
    export default {
        name: "add",
        components:{SelectImages},
        data() {
            return {
                statusTxt:'上架',
                loading:false,
                image:'',
                form: {
                    id:0,
                    type: 0,
                    status:true,
                    pic:'',
                    resource:''
                },
                options: [
                    {
                        value: 0,
                        label: '仅展示图片'
                    },
                    {
                        value: 1,
                        label: '跳转页面'
                    }
                ],

            }
        },
        methods: {
            ...mapActions('slideShow',['addSlideShow','getSlideShowDetail']),
            uploadSuccess(path){

              this.form.pic = path
                console.log(this.form.pic)
            },
            async onSubmit() {
                let _this = this
                this.loading = true
                console.log(this.form)
                if(this.form.type === 0) this.form.resource = ''
                await this.addSlideShow(this.form).then( res => {
                    if(res.ret == 0){
                        _this.$message.success('修改成功！')
                        _this.$router.push('/slideShow')
                    }
                })
                this.loading = false
            },
            setStatus(status){
                console.log(status)
                if(this.form.status){
                    this.statusTxt = '上架'
                }else{
                    this.statusTxt = '下架'
                }
                this.form.status = status
            },
            selectType(val){
                this.form.type = val
            },
            onSelect(image){
                this.image =  imageUrl + image.path
                this.form.pic = image.path
            },
            onDelete(){
                this.image = ''
            },
        },
        async mounted() {
            this.form.id = this.$route.params.id
            let detail = await this.getSlideShowDetail({id:this.form.id})
            this.form.type = detail.data.type
            this.form.status = detail.data.status
            this.form.pic = detail.data.pic
            if(this.form.pic) this.image = config.imageUrl + this.form.pic
            this.form.resource = detail.data.resource
        }
    }
</script>

<style scoped>

</style>
